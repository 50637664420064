




























import Vue from 'vue'
import CMSRenderer from '../components/CMSRenderer.vue'
import { ContentTree, IAdapterCMS } from '../types'
import { PreviewResult, Query } from '@simpl/core/types/graphql'
import { GET_CONTENT_PREVIEW } from '../graphql'
import { getTreeContent } from '../utils/tree-operations'
import normalizeTree from '../utils/normalize-tree'
import { MUTATIONS } from '@simpl/auth/store/consts'
import pusher from '@simpl/core/plugins/pusher'
import type { Channel } from '@simpl/core/plugins/pusher'
import VoidAdapter from '@simpl/cms-components/adapters/VoidAdapter'

export default Vue.extend({
  name: 'CMSPreview',

  components: {
    CMSRenderer
  },

  props: {
    token: String,
    screen: [String, Number],
    language: String
  },

  data () {
    return {
      loading: 0,
      adapter: null! as IAdapterCMS,
      previewResult: null! as PreviewResult,
      channel: null! as Channel
    }
  },

  computed: {
    content (): ContentTree | null | void {
      if (!this.previewResult?.content) return

      return getTreeContent(this.previewResult!.content, parseInt(this.screen as string))
    },
    expired (): boolean {
      return !!this.previewResult?.expired
    }
  },

  apollo: {
    previewResult: {
      query: GET_CONTENT_PREVIEW,

      fetchPolicy: 'network-only',

      variables (): object {
        return {
          token: this.token
        }
      },

      update (data: Query): PreviewResult | null | void {
        if (!data) {
          return
        }

        const contentPreview = {
          ...(data.contentPreview || {})
        }

        if (contentPreview) {
          contentPreview.content = normalizeTree(contentPreview.content)
        }

        return contentPreview
      },

      loadingKey: 'loading'
    }
  },

  async created () {
    if (!this.$store.state.auth?.user?.id) {
      this.$store.commit(`auth/${MUTATIONS.SET_USER_LANGUAGE}`, this.language)
    }

    this.channel = pusher.subscribe(`cms-${this.token}`)
    this.channel.bind_global((a: any, b: any, c: any) => {
      console.log(a, b, c)
    })

    this.adapter = new VoidAdapter()
    await this.adapter.init(null, null)

    console.log('CMSPreview created', this.adapter)
  }
}
)
